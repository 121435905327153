export function getPlaceholderHeight(width, height) {
  return `${((height / width) * 100).toFixed(2)}%`
}

// 页面标识映射pageName -> ccc pageType
export const PAGE_NAME_MAP_PAGE_KEY = {
  page_real_class: 'page_real_class',
  page_select_class: 'page_select_class',
  page_daily_new: 'daily_new',
  page_selling_point: 'page_selling_point',
  page_goods_group: 'shein_picks',
  page_picked: 'page_picked',
  page_search: 'page_search'
}


export function getIsCustomAttr (attr, key = 'attr_values') {
  return attr?.[key]?.some(item => item?.[key]?.length > 0)
}

/**
 * filterBar queryParams
 */
export const filterBarQueryParams = [
  'sort',
  'daily',
  'navId',
  'child_cat_id',
  'child_cat_id_void',
  'attr_values',
  'tsp_ids',
  'attr_ids',
  'tag_ids',
  'min_price',
  'max_price',
  'pic_nav_id',
  'pic_nav_type',
  'pic_cate_id',
  'pic_tab_id',
  'kid_id',
]

/**
 * url 的 search 是否有 filterBar的queryParams
 * @param {Object} catInfo catInfo 对象包含了 url 的 search
 * @returns { Boolean }
 */
export function hasFilterBarQueryParams(catInfo) {
  return filterBarQueryParams.some(key => !!catInfo[key])
}
