<template>
  <s-side-filter
    :common-config="commonConfig"
    :nav-config="navConfig"
    :panel-config="panelConfig"
    :ceiling="true"
    :lazy-img="LAZY_IMG_SOLID_COLOR"
    :transform-img="transformImg"
    :selected-filter="selectedFilter"
    :show-selected-filter="abtFilterSelected"
    :disabled="requestLoading"
    :header-height="headerHeight"
    @filter-change="filterChange"
    @reset-all-filters="resetAllFilters"
    @reset-cur-filter="resetCurFilter"
    @del-selected-filter="delSelectedFilter"
    @ie-fixed="ieFixed"
    @is-sticky="assignState({ isSticky: $event })"
  >
    <ClientOnly>
      <!-- cccx图片banner -->
      <CccxComp
        v-if="cccxContextContent && catInfo.type !== 'store'"
        :cccx-context="cccxContext"
        :content="[cccxContextContent]"
      />
      <!-- 老ccc图片banner -->
      <template v-else>
        <SelectionBanner 
          v-if="catInfo.type !== 'store'"
          :banner-block-name="3" 
        />
      </template>
    </ClientOnly>
  </s-side-filter>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { parseQueryString } from '@shein/common-function'
import { cloneDeep } from 'lodash'
import { ClientOnly } from '@sheinfe/vue-client-only'
import lazyLoadComponent from '../../utils/lazy-load-component'
import { formatData } from '../../../components/filter/common'
import mixin from './mixins'
import SkeletonBox from '../SkeletonBox'
import SSideFilter from 'public/src/pages/components/filter/side-filter/SideFilter.vue'
import { getIsCustomAttr } from 'public/src/pages/product_list_v2/utils/index.js'
import filterAna from '../../analysis/filter'

const DAILY_KEY = 'daily'
const CATE_KEY = 'cate'
const TSP_KEY = 'tsp'
const ATTR_KEY = 'attr'
const PRICE_KEY = 'priceRange'

export default {
  name: 'SideFilter',
  components: {
    ClientOnly,
    SelectionBanner: lazyLoadComponent({
      componentFactory: () => import('../SelectionBanner'),
      loading: SkeletonBox,
    }),
    CccxComp: lazyLoadComponent({
      componentFactory: () => import('../CccxComp.vue'),
      loading: SkeletonBox,
    }),
    SSideFilter
  },
  mixins: [mixin],
  props: {
    code: {
      type: String,
      default: 'goodsList',
    },
    requestLoading: {
      type: Boolean,
      default: false
    },
    headerHeight: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      navConfig: [],
      panelConfig: [],
      newDailyDates: { children: [] },
      icons: {
        normal: 'sui_icon_plus_16px',
        active: 'sui_icon_min_16px',
      },
      queryObj: {},
      selectedFilter: [],
      abtFilterSelected: false,
      abtFilterUnfold: false
    }
  },
  inject: ['transformImg'],
  computed: {
    ...mapState([
      'language',
      'currency',
      'currencies',
      'lang',
      'Results',
      'GB_cssRight',
      'LAZY_IMG_SOLID_COLOR',
      'retainQueryObj',
      'dailyRetainQueryObj',
      'getJumpUrl'
    ]),
    ...mapGetters([
      'filterData',
      'selectedCate',
      'selectedTsps',
      'selectedAttrs',
      'catInfo',
      'fhContext',
      'googleContext',
      'searchKeywords',
      'filterHotAttrs',
      'listAbtResult',
      'cccxConfig',
      'cccxFilterConfig'
    ]),
    commonConfig() {
      return {
        currency: this.currency,
        currencies: this.currencies,
        lang: this.lang,
        GB_cssRight: this.GB_cssRight,
        language: this.language,
      }
    },
    cccxContext() {
      return this.cccxConfig?.left_list_zone || {}
    },
    cccxContextContent() {
      return this.cccxContext?.content?.[0]
    }
  },
  watch: {
    filterData: {
      handler(filterData) {
        // 重置数据
        this.navConfig = []
        this.panelConfig = []

        // 前置abt处理
        this.handleAbt()
        // 处理筛选数据
        this.handleDailyDates(filterData.dailyDates)
        this.handleCates(filterData.filterCates)
        this.handleTsps(filterData.filterTsps)
        // 服务端 SSR 时仅展示前三个
        this.handleAttrs(filterData.filterAttrs)
        this.handlePrics(filterData.filterPrices)

        this.sortSideFilterByCccxFilter() 

        // 处理回显数据
        this.handleEchoData()

        this.$nextTick(() => {
          this.handleFilterMounted()
        })
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations(['jumpUrl', 'assignState']),
    /** attrs 渲染数量（减少SSR时不可见内的输出） */
    getRenderAttrs(attrs) {
      if (!attrs || !attrs.length) return []
      // 是否客户度
      const isClient = typeof window !== 'undefined'
      if (isClient) {
        return attrs
      }

      return attrs.slice(0, 3)
    },
    handleAbt() {
      const { FilterUnfold, FilterSelected } = this.listAbtResult
      
      this.abtFilterUnfold = FilterUnfold?.p == 'Unfold=True'
      this.abtFilterSelected = FilterSelected?.p == 'Selected=True'
    },
    // 处理daily new日期数据
    handleDailyDates(dates) {
      if (dates && !Object.keys(dates).length) return
      this.newDailyDates.children = [] // 重置

      const { daily } = this.catInfo

      // 重组dailyDates数据，复用radio组件
      Object.keys(dates).forEach((key) => {
        this.newDailyDates.children.push({ label: dates[key], label_id: key })
      })
      
      // navBar配置
      this.navConfig.push({
        key: DAILY_KEY,
        text: this.language.SHEIN_KEY_PC_15627,
        icons: this.icons,
        open: 1, // 是否展开
      })
      
      const selectDailyType = this.catInfo.show_daily == 1
      let sliceLen = selectDailyType ? 6 : 0
      if (
        daily &&
        selectDailyType &&
        this.newDailyDates.children &&
        this.newDailyDates.children.length
      ) {
        const labels = this.newDailyDates.children.map((el) => el.label_id)
        const index = labels.indexOf(daily)
        sliceLen = index < 6 ? 6 : 0
      }

      // panel配置
      this.panelConfig.push({
        type: 'radio',
        key: DAILY_KEY,
        data: this.newDailyDates,
        curSelectedId: daily,
        sliceLen: sliceLen,
        selectedNum: daily ? 1 : 0,
      })
    },
    // 处理分类数据
    handleCates(cates) {
      if (!(cates?.children && Array.isArray(cates.children) && cates.children.length)) return

      const { child_cat_id } = this.catInfo

      // 转成tree组件通用字段
      const newData = formatData({
        data: cloneDeep(cates),
        name: 'cat_name',
        id: 'cat_id',
        // pId: 'parent_id',
        subTree: 'children',
        isOpen: 'isShowChild',
        lastIndex: 'lastSelectedIndex',
        selected: 'isSelected',
      })

      // navBar配置
      this.navConfig.push({
        key: CATE_KEY,
        text: this.language.SHEIN_KEY_PC_15811,
        icons: this.icons,
        open: 1, // 是否展开
      })

      // panel配置
      this.panelConfig.push({
        type: 'radio',
        key: CATE_KEY,
        data: newData,
        sliceLen: 6,
        curSelectedId: child_cat_id,
        selectedNum: child_cat_id ? 1 : 0,
        step: this.catInfo.pageName == 'page_search' ? 20 : 0,
      })
    },
    // 处理tsp筛选数据
    handleTsps (tsps) {
      if (!(tsps && tsps.length)) return

      tsps.forEach(({ tagGroupName, tagGroupId, tagList, selectedNum, open, lastSelectedIndex, nodeType = '' }) => {
        const children = []

        tagList.forEach((tsp) => {
          children.push({
            label_id: tsp.tagId,
            label: tsp.tagName,
            label_en: tsp.tagValueEn,
            id: tsp.tagId,
            isSelected: tsp.isSelected,
          })
        })

        this.navConfig.push({
          key: `${TSP_KEY}_${tagGroupId}`,
          text: tagGroupName,
          icons: this.icons,
          open: this.abtFilterUnfold ? 1 : open, // 是否展开
          resetIcon: !this.abtFilterSelected, // 删除按钮
        })

        this.panelConfig.push({
          type: 'checkbox',
          key: TSP_KEY,
          nodeType,
          data: { children, lastIndex: lastSelectedIndex },
          selectedNum, // 当前选中的数量
          sliceLen: 6,
          id: tagGroupId,
        })
      })
    },
    // 处理属性数据
    handleAttrs(attrs) {
      if (!(attrs && attrs.length)) return

      attrs.forEach((attr) => {
        const isCustomAttr = getIsCustomAttr(attr)

        let attrObj = {
          children: [],
          lastIndex: attr.lastSelectedIndex,
        }
        if (attr.show_group) {
          attr.groups.forEach((item) => {
            attrObj.children.push({
              label_id: item.attr_filter,
              img: item.group_image,
              label: item.group_name, // hover使用
              label_en: item.group_name_en,
              id: item.attr_id,
              isSelected: item.isSelected,
              isHot: !!item.hotMark,
              nodeType: item.nodeType
            })
          })
        } else {
          attr.attr_values.forEach((item) => {
            if (isCustomAttr) {
              const obj =  {
                key: ATTR_KEY,
                type: item.attr_values?.[0]?.attr_image ? 'symbol' : 'checkbox',
                label_id: item.attr_filter,
                label: item.attr_value,
                label_en: item.attr_value_en,
                id: item.attr_id,
                isSelected: item.isSelected,
                isHot: !!item.hotMark,
                children: [],
                nodeType: item.nodeType
              }
              item.attr_values.forEach(sub => {
                obj.children.push({
                  key: ATTR_KEY,
                  p_id: attr.attr_id,
                  img: sub.attr_image,
                  label_id: sub.attr_filter,
                  label: sub.attr_value,
                  label_en: sub.attr_value_en,
                  id: sub.attr_id,
                  isSelected: sub.isSelected,
                  isHot: !!sub.hotMark,
                  nodeType: sub.nodeType
                })
              })
              attrObj.children.push(obj)
            } else { 
              attrObj.children.push({
                p_id: attr.attr_id,
                label_id: item.attr_filter,
                label: item.attr_value,
                label_en: item.attr_value_en,
                id: item.attr_id,
                isSelected: item.isSelected,
                isHot: !!item.hotMark,
                nodeType: item.nodeType
              })
            }
          })
        }

        const isSize = +attr.attr_id == 87

        // navBar配置
        this.navConfig.push({
          text: attr.attr_name,
          icons: this.icons,
          key: `${ATTR_KEY}_${attr.attr_id}`,
          open: this.abtFilterUnfold ? 1 : attr.isShowChild, // 是否展开
          resetIcon: !this.abtFilterSelected, // 删除按钮
        })
        // panel配置
        this.panelConfig.push({
          type: attr.show_group && !attr.show_group_txt ? 'symbol' : 'checkbox',
          key: ATTR_KEY,
          data: attrObj,
          id: attr.attr_id,
          isCustomAttr,
          nodeType: attr.nodeType || '',
          selectedNum: attr.selectedNum,
          itemStyles: isSize ? { minWidth: '50%' } : {},
          sliceLen: attr.show_group && !attr.show_group_txt ? 0 : 6,
        })
      })
    },
    // 处理价格区间数据
    handlePrics(prices) {
      const { min_price: curMin, max_price: curMax } = this.catInfo
      const { min_price: startMin, max_price: startMax } = prices
      if (startMin === startMax) return
      // navBar配置
      this.navConfig.push({
        key: 'dragPrice',
        text: this.language.SHEIN_KEY_PC_15806,
        resetIcon: !this.abtFilterSelected, // 删除按钮
        extraText: `(${this.currency})`,
      })
      // panel配置
      this.panelConfig.push({
        type: 'dragPrice',
        key: PRICE_KEY,
        data: prices,
        selectedNum: curMin && curMax ? 1 : 0,
        curMin,
        curMax,
      })
    },
    handleEchoData() {
      const { daily, min_price: curMin, max_price: curMax, child_cat_id, tsp_ids, attr_ids } = this.catInfo
      this.selectedFilter = []

      /** 注意：选中的筛选词由 0 - 4 依次排序 */

      // 选中的日期 - 0
      if (daily) {
        this.selectedFilter.push({ key: DAILY_KEY, label_id: daily, label: this.filterData?.dailyDates?.[daily] })
      }
      // 选中的分类 - 1
      this.selectedCate?.forEach(cate => {
        this.selectedFilter.push({ key: CATE_KEY, label_id: cate.cat_id, label: cate.cat_name })
      })
      // 选中的tsp筛选 - 2
      this.selectedTsps?.forEach(tsp => {
        this.selectedFilter.push({ key: TSP_KEY, label_id: tsp.tagId, label: tsp.tagValue })
      })
      // 选中的属性 - 3
      this.selectedAttrs?.forEach(attr => {
        const id = attr.attr_filter
        const name = attr.attr_value || attr.group_name
        const img = attr.group_image
        this.selectedFilter.push({ key: ATTR_KEY, label_id: id, label: name, img })
      })
      // 价格区间 - 4
      const hasPriceRange = curMin && curMax
      if (hasPriceRange) {
        const { symbol_left, symbol_right } = this.currencies?.[this.lang]?.[this.currency] || {}
        const minPrice = symbol_left + curMin + symbol_right
        const maxPrice = symbol_left + curMax + symbol_right
        this.selectedFilter.push({ key: PRICE_KEY, label: minPrice + '-' + maxPrice, minPrice: curMin, maxPrice: curMax })
      }
      // 处理当前记忆的attr/tsp
      if (this.Results.staticFilterId) {
        if (this.Results.staticFilterType === ATTR_KEY) {
          const item = this.filterData?.filterAttrs.find(item => item.attr_id === this.Results.staticFilterId)
          if (item) {
            const isCustomAttr = getIsCustomAttr(item)
            const key = item.show_group ? 'groups' : 'attr_values'
            const childList = isCustomAttr ? item[key]?.reduce((acc, cur) => acc.concat(cur[key]), []) : item[key]

            childList.forEach(obj => {
              if (!obj.isSelected || this.selectedFilter.find(selectItem => selectItem.label_id == obj.attr_filter)) return

              this.selectedFilter.push({ key: ATTR_KEY, label_id: obj.attr_filter, label: obj.attr_value || obj.group_name, img: obj.group_image })
            })
          }
        } else if (this.Results.staticFilterType === TSP_KEY) {
          const item = this.filterData?.filterTsps.find(item => item.tagGroupId === this.Results.staticFilterId)
          item && item.tagList.forEach(obj => {
            if (!obj.isSelected || this.selectedFilter.find(selectItem => selectItem.label_id == obj.tagId)) return

            this.selectedFilter.push({ key: TSP_KEY, label_id: obj.tagId, label: obj.tagValue })
          })
        }
        
        
      }
      if ((daily || child_cat_id || tsp_ids || attr_ids || hasPriceRange) && this.abtFilterSelected) {
        // 埋点
        filterAna.exposeSelectedFilter(this.catInfo)
        filterAna.exposeClearAll(this.catInfo)
      }
    },
    // 更新筛选
    filterChange(value) {
      this.queryObj = parseQueryString(location.search)

      const { key, label_en, label_id, operationType, id, curMin, curMax, startMin, startMax, nodeType } = value
      let isClickAttr = false

      if (key == DAILY_KEY) { // daily
        // analysis
        filterAna.clickDaily(value)
          
        if (operationType == 'delete') {
          this.queryObj.daily = undefined
          filterAna.delSelectedFilter({ date: label_id }) // 反选埋点 - 新加的
        } else {
          this.deleteQueryObj(this.dailyRetainQueryObj) // daily重置所有其他筛选项
          Object.assign(this.queryObj, { daily: label_id })
        }
      } else if (key == CATE_KEY) { // 分类
        // analysis
        filterAna.clickCategory(value, this.fhContext.categories, this.googleContext.gfilter)
        // analysis
        if (operationType == 'delete') {
          this.queryObj.child_cat_id = undefined
          filterAna.delSelectedFilter({ categoryId: label_id }) // 反选埋点 - 新加的
        } else {
          Object.assign(this.queryObj, { child_cat_id: label_id })
        }
      } else if (key === TSP_KEY || nodeType == 7) { // tsp筛选
        const tspId = nodeType == 7 ? `${value.p_id}_${id}` : id
        this.setStaticAttrSetId(value, key)
        let tspIds = this.selectedTsps?.map(item => item.tagId) || []
        this.selectedAttrs.forEach(item => item.nodeType == 7 && tspIds.push(`${item.attr_id}_${item.attr_filter}`))
        if (operationType == 'delete') {
          const i = tspIds.indexOf(tspId)
          if (i > -1) {
            tspIds.splice(i, 1)
          }
          filterAna.delSelectedFilter({ tsps: label_id }) // 反选埋点 - 新加的
        } else {
          tspIds.push(tspId)
        }

        Object.assign(this.queryObj, { tsp_ids: tspIds.join(',') })

        // analysis
        filterAna.clickTsp(tspIds.join('-'))
      } else if (key == ATTR_KEY) { // 属性
        isClickAttr = true
        this.setStaticAttrSetId(value, key)
        const { attrFilterArr, attrValuesArr } = this.handleAttrData(this.selectedAttrs)
        if (operationType == 'delete') {
          let index = attrFilterArr.findIndex((item) => item === label_id)
          while(index > -1) {
            attrFilterArr.splice(index, 1)
            attrValuesArr.splice(index, 1)
            index = attrFilterArr.findIndex((item) => item === label_id)
          }
          filterAna.delSelectedFilter({ attribute: label_id }) // 反选埋点 - 新加的
        } else {
          attrFilterArr.push(label_id)
          attrValuesArr.push(label_en)
        }
        // analysis
        filterAna.clickAttribute({
          value,
          attrFilterArr,
          filterHotAttrs: this.filterHotAttrs,
          facet: this.fhContext[`attr${value.id}`],
          gfilter: this.googleContext.gfilter,
          isHot: value.isHot
        })

        Object.assign(this.queryObj, {
          attr_values: attrValuesArr.join('-'),
          attr_ids: attrFilterArr.join('-'),
          exc_attr_id: id,
        })
      } else if (key == PRICE_KEY) { // 价格区间
        // analysis
        filterAna.selectPriceRange({ value, fhContext: this.fhContext, googleContext: this.googleContext })

        if (curMin != startMin || curMax != startMax) {
          Object.assign(this.queryObj, { min_price: curMin, max_price: curMax })
        } else {
          const { min_price, max_price } = this.queryObj
          filterAna.delSelectedFilter({ priceRange: `${min_price}\`${max_price}` }) // 反选埋点 - 新加的
          Object.assign(this.queryObj, { min_price: undefined, max_price: undefined })
        }
      }

      // 记录source，sourceStatus，给监控使用
      Object.assign(this.queryObj, { source: 'insideFilter', sourceStatus: 1 })
      if (operationType == 'delete') {
        Object.assign(this.queryObj, { sourceStatus: 0 })
      }

      this.handleJumpUrl(isClickAttr)
      this.$emit('filterChange', { queryObj: this.queryObj })
    },
    setStaticAttrSetId(value, type) {
      this.Results.staticFilterType = type
      const { label_id, operationType } = value
      let id = ''
      let isBreak = false
      let isSelected = operationType && operationType !== 'delete'

      type === ATTR_KEY
        ? this.Results.filterAttrs?.forEach(item => {
          if (isBreak) return
          const isCustomAttr = getIsCustomAttr(item)
          const { groups = [], attr_values = [] } = item || {}
          const list = attr_values?.length ? attr_values : groups
          const childList = isCustomAttr ? list?.reduce((acc, cur) => acc.concat(cur.attr_values), []) : list
          childList?.forEach(obj => {
            if (obj.attr_filter == label_id) {
              isBreak = true
              id = item.p_id || item.attr_id
              obj.isSelected = isSelected
              // 全部取消时，重置id
              !isSelected && !childList.some(item => item.isSelected) && (id = '')
            }
          })
        })
        : this.Results.filterTsps?.forEach(item => {
          if (isBreak) return
          const list = item?.tagList || []
          list?.forEach(obj => {
            if (obj.tagId == label_id) {
              isBreak = true
              id = item.tagGroupId
              obj.isSelected = isSelected
              // 全部取消时，重置id
              !isSelected && !list.some(item => item.isSelected) && (id = '')
            }
          })
        })

      this.Results.staticFilterId = id
    },
    // 处理属性数据
    handleAttrData (selectedAttrs) {
      const list = selectedAttrs.filter(item => item.nodeType != 7)
      const attrFilterArr = list.map((attr) => attr.attr_filter) || []
      const attrValuesArr = list.map((attr) => attr.attr_value_en || attr.group_name_en) || []
      return { attrFilterArr, attrValuesArr }
    },
    // 重置全部筛选
    resetAllFilters() {
      this.Results.staticFilterId = ''
      this.queryObj = parseQueryString(location.search)
      this.deleteQueryObj(this.retainQueryObj)
      this.handleJumpUrl()
      this.$emit('filterChange', { queryObj: this.queryObj })

      // 埋点
      filterAna.clickClearAll(this.catInfo)
    },
    deleteQueryObj(retainObj) {
      Object.keys(this.queryObj).forEach((key) => {
        if (!retainObj[key]) {
          this.queryObj[key] = undefined
        }
      })
    },
    // 重置当前筛选小项
    resetCurFilter({ id, key }) {
      this.queryObj = parseQueryString(location.search)

      if (key == ATTR_KEY) {
        const newSelectedAttrs = this.selectedAttrs?.filter(item => item.attr_id !== id) || []
        this.handleAttrsQuery(newSelectedAttrs)
      } else if (key == PRICE_KEY) {
        this.handlePriceRangeQuery()
      } else if (key == TSP_KEY) {
        const newSelectedTsps = this.selectedTsps?.filter(item => item.tagGroupId !== id) || []
        this.handleTspQuery(newSelectedTsps)
      }

      this.handleJumpUrl()
      this.$emit('filterChange', { queryObj: this.queryObj })
    },
    // 单个删除顶部选中的筛选词
    delSelectedFilter(value, { delFrom }) {
      const { key, label_id, minPrice, maxPrice } = value
      const delSelectedFilterInfo = {}
      this.queryObj = parseQueryString(location.search)
      if (key == DAILY_KEY) {
        delSelectedFilterInfo.date = label_id
        this.queryObj.daily = undefined
      } else if (key == CATE_KEY) {
        delSelectedFilterInfo.categoryId = label_id
        this.queryObj.child_cat_id = undefined
      } else if (key == ATTR_KEY) {
        this.setStaticAttrSetId(value, key)
        delSelectedFilterInfo.attribute = label_id
        const newSelectedAttrs = this.selectedAttrs?.filter(item => item.attr_filter !== label_id) || []
        this.handleAttrsQuery(newSelectedAttrs)
      } else if (key == PRICE_KEY) {
        delSelectedFilterInfo.priceRange = `${minPrice}\`${maxPrice}`
        this.handlePriceRangeQuery()
      } else if (key == TSP_KEY) {
        this.setStaticAttrSetId(value, key)
        delSelectedFilterInfo.tsps = label_id
        const newSelectedTsps = this.selectedTsps?.filter(item => item.tagId !== label_id)
        this.handleTspQuery(newSelectedTsps)
      }

      // 头部筛选词，点击的删除才记录
      if (delFrom === 'filterWords') {
        Object.assign(this.queryObj, { source: 'insideFilter', sourceStatus: 0 })
      }

      this.handleJumpUrl()
      this.$emit('filterChange', { queryObj: this.queryObj })

      // 埋点
      filterAna.delSelectedFilter(delSelectedFilterInfo)
    },
    // 处理属性query
    handleAttrsQuery(newSelectedAttrs) {
      const { attrFilterArr, attrValuesArr } = this.handleAttrData(newSelectedAttrs)
      Object.assign(this.queryObj, {
        attr_values: attrValuesArr.join('-'),
        attr_ids: attrFilterArr.join('-'),
        exc_attr_id: '',
      })
    },
    // 处理价格区间query
    handlePriceRangeQuery() {
      Object.assign(this.queryObj, { min_price: undefined, max_price: undefined })
    },
    // 处理tsp query
    handleTspQuery(newSelectedTsps) {
      const tspIds = newSelectedTsps?.map(item => item.tagId).join()
      Object.assign(this.queryObj, { tsp_ids: tspIds })
    },
    // 筛选后跳转
    handleJumpUrl(isClickAttr) {
      this.queryObj.page = 1

      // mixin方法
      this.handleUrl(isClickAttr)
    },
    // ie样式兼容
    ieFixed(isFixed) {
      this.$emit('ieFixed', isFixed)
    },
    handleFilterMounted() {
      filterAna.exposeFilter({ 
        panelConfig: this.panelConfig, 
        ATTR_KEY,
        CATE_KEY,
        DAILY_KEY
      })
    },
    sortSideFilterByCccxFilter () {
      const { filterRule = [] } = this.cccxFilterConfig || {}
      if (!filterRule.length) return

      const newNavConfig = []
      const newPanelConfig = []
      const { navConfig = [], panelConfig = [] } = this
      // date 处理
      const dateIndex = panelConfig.findIndex(item => item?.key === DAILY_KEY)
      if (dateIndex > -1) {
        newNavConfig.push(navConfig[dateIndex])
        newPanelConfig.push(panelConfig[dateIndex])
      }

      // cate 处理
      const cateCccxConfig = filterRule.find(node => node.nodeType == 6) // cccxConfig中分类配置
      const cateIndex = panelConfig.findIndex(item => item?.key === CATE_KEY)
      if (cateIndex > -1 && !cateCccxConfig) { 
        newNavConfig.push(navConfig[cateIndex])
        newPanelConfig.push(panelConfig[cateIndex])
      }
      // cccxConfig 处理
      filterRule.forEach(node => {
        let index = node.nodeType == 6
          ? panelConfig.findIndex(item => item?.key == CATE_KEY)
          : panelConfig.findIndex(item => item.id == node.nodeId && item.nodeType == node.nodeType)

        if (index < 0) return

        newNavConfig.push(navConfig[index])
        newPanelConfig.push(panelConfig[index])
      })

      // price处理
      const priceIndex = panelConfig.findIndex(item => item?.key === PRICE_KEY)
      if (priceIndex > -1) {
        newNavConfig.push(navConfig[priceIndex])
        newPanelConfig.push(panelConfig[priceIndex])
      }

      this.navConfig = newNavConfig
      this.panelConfig = newPanelConfig
    }
  },
  emits: ['ieFixed', 'filterChange'],
}
</script>
